<template>
  <CompanyShare :companyId="this.$route.query.id" />
</template>

<script>
import CompanyShare from '@/components/CompanyShare';

export default {
  // name: 'CompanyShare',
  data() {
    return {};
  },

  computed: {},

  created() {},

  components: {
    CompanyShare,
  },
};
</script>
